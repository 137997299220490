import axios from "axios";
import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { toast } from "react-toastify";

import { Context } from "../../context/context";
import Avatar from "../../components/avatar/avatar.component";
import FormInput from "../../components/form-input/form-input.component";

import {ReactComponent as PlusIcon} from '../../assets/Plus_Icon.svg';

import './devices.styles.scss';

const Devices = (props) => {
    // state
    const [myDevices, setMyDevices] = useState([]);
    const [networkMemberDevices, setNetworkMemberDevices] = useState([]);
    const [networkViewerDevices, setNetworkViewerDevices] = useState([]);
    const [invites, setInvites] = useState([]);
    const [showInvitationsModal, setShowInvitationsModal] = useState(false);
    const [inviteModalIndex, setInviteModalIndex] = useState(0);
    const [invitedDeviceNickname, setInvitedDeviceNickname] = useState('');
    const [showAddNewDeviceModal, setShowAddNewDeviceModal] = useState(false);
    const [newDeviceID, setNewDeviceID] = useState('');
    const [newDeviceNickname, setNewDeviceNickname] = useState('');

    // state
    const { state, dispatch } = useContext(Context);
    const {user} = state;

    // router
    const navigate = useNavigate();

    useEffect( async() => {
        await loadMyDevices();
        loadNetworkMemberDevices();
        loadNetworkViewerDevices();
        loadInvites();
    }, [])

    useEffect( () => {
        setInviteModalIndex(0);
    }, [showInvitationsModal])
    
    useEffect( () => {
        setInvitedDeviceNickname('');
    }, [showInvitationsModal, inviteModalIndex])

    const loadMyDevices = async() => {
        const {data} = await axios.post('/app-api/user/mydevices');
        if(data) setMyDevices(data.devices);
    }

    const loadNetworkMemberDevices = async() => {
        const {data} = await axios.post('/app-api/user/networkdevices',{
            targetRole: "Member"
        });
        if(data) setNetworkMemberDevices(data.devices);
    }

    const loadNetworkViewerDevices = async() => {
        const {data} = await axios.post('/app-api/user/networkdevices',{
            targetRole: "Viewer"
        });
        if(data) setNetworkViewerDevices(data.devices);
    }

    const loadInvites = async() => {
        const {data} = await axios.get('/app-api/user/invites');
        if(data) setInvites(data.invites);
    }

    const handleAcceptInvite = async() => {
        if(!invitedDeviceNickname) return toast.error('Enter a nickname for the Device');
        try {
            const {data} = await axios.post('/app-api/user/accept-invite',{
                inviteId: invites[inviteModalIndex].id,
                nickname: invitedDeviceNickname
            });
            const index = inviteModalIndex;
            if(inviteModalIndex>0){
                setInviteModalIndex(inviteModalIndex-1);
            }else{
                setShowInvitationsModal(false);
            }
            const newInvites = [...invites];
            newInvites.splice(index, 1);
            setInvites(newInvites);
            if(invites[inviteModalIndex].targetRole === "Member"){
                const updatedNetworkMemberDevices = [...networkMemberDevices, {
                    id: invites[inviteModalIndex].deviceId,
                    nickname: invitedDeviceNickname,
                }];
                setNetworkMemberDevices(updatedNetworkMemberDevices);
            }else{
                const updatedNetworkViewerDevices = [...networkViewerDevices, {
                    id: invites[inviteModalIndex].deviceId,
                    nickname: invitedDeviceNickname,
                }];
                setNetworkViewerDevices(updatedNetworkViewerDevices);
            }
            const nicknames = {...state.user.nicknames};
            nicknames[invites[inviteModalIndex].deviceId] = invitedDeviceNickname;
            dispatch({ type: "CHANGE_NICKNAME", payload: {
                ...state.user,
                nicknames
            }});
            toast.success('Invitation Accepted');
        } catch (err) {
            console.log(err);
            toast.error(err.response.data);
        }
    }

    const handleRejectInvite = async() => {
        try {
            const {data} = await axios.post('/app-api/user/reject-invite',{
                inviteId: invites[inviteModalIndex].id
            });
            const index = inviteModalIndex;
            if(inviteModalIndex>0){
                setInviteModalIndex(inviteModalIndex-1);
            }else{
                setShowInvitationsModal(false);
            }
            const newInvites = [...invites];
            newInvites.splice(index, 1);
            setInvites(newInvites);
            toast.success('Invitation Rejected');
        } catch (err) {
            console.log(err);
            toast.error(err.response.data);
        }
    }

    const handleAddNewDevice = async() => {
        try {
            if(!newDeviceID)
                return toast.error('Please Enter a Device ID');
            if(!newDeviceNickname)
                return toast.error('Please Enter a Nickname');
            
            const {data} = await axios.post('/app-api/user/add-device',{
                deviceId: newDeviceID,
                nickname: newDeviceNickname,
            });
            const updatedMyDevices = [...myDevices, {
                id: newDeviceID,
                nickname: newDeviceNickname,
                hasRecentMissedPill: false,
                sync:{
                    status: true,
                    syncedTime: null,
                    updatedTime: null,
                },
            }];
            setShowAddNewDeviceModal(false);
            setNewDeviceID('');
            setNewDeviceNickname('');
            setMyDevices(updatedMyDevices);

            const nicknames = {...state.user.nicknames};
            nicknames[newDeviceID] = newDeviceNickname;
            dispatch({ type: "CHANGE_NICKNAME", payload: {
                ...state.user,
                nicknames
            }});

            toast.success('Device Added');
        } catch (err) {
            console.log(err);
            toast.error(err.response.data);
        }
    }

    return(
        <div className="devices">
            {(!myDevices) ?
                <div></div> :
                <>
                    {
                        invites.length > 0 &&
                        <div className="invite-bar">
                            <span className="material-icons-outlined">mark_email_unread</span>
                            <h4>{`You have ${invites.length} pending invitation${invites.length>2 ? 's' : ''}`}</h4>
                            <button className="open-invitation-button" onClick={ () => setShowInvitationsModal(true) }>
                                <h4>{`Open Invitation${invites.length>2 ? 's' : ''}`}</h4>
                            </button>
                        </div>
                    }
                    {/* <pre>{JSON.stringify(invites, null, 4)}</pre> */}
                    <div className="devices-header"><h1>My Devices</h1></div>
                    <div className="devices-container">
                        <div className="device-card centered" key={0} onClick={ () => setShowAddNewDeviceModal(true) }>
                            <PlusIcon className = "image"/>
                            <h4>Add New Device</h4>
                        </div>
                        {myDevices.map( ({id, nickname, sync, hasRecentMissedPill}) => {
                            return(
                                <div className="device-card" key={id} onClick={ () => navigate(`/device/${id}/dashboard`) }>
                                    <h4>{nickname ? nickname : ''}</h4>
                                    {id}
                                    <div className="card-info-icon-container">
                                        {(hasRecentMissedPill) &&
                                            <div className="card-info-icon red">
                                                <span className="material-icons-outlined">notification_important</span>
                                            </div>
                                        }
                                        {(sync.status) ?
                                            <div className="card-info-icon green">
                                                <span className="material-icons-outlined">sync</span>
                                            </div>
                                            :
                                            <div className="card-info-icon red">
                                                <span className="material-icons-outlined">sync_problem</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                        
                    </div>
                    <div className="devices-header"><h1>My Network Devices</h1></div>
                    <div className="devices-container">
                        {networkMemberDevices.map( ({id, nickname, sync, hasRecentMissedPill}) => {
                            return(
                                <div className="device-card" key={id} onClick={ () => navigate(`/device/${id}/dashboard`) }>
                                    <h4>{nickname ? nickname : ''}</h4>
                                    {id}
                                    <div className="card-info-icon-container">
                                        {(hasRecentMissedPill) &&
                                            <div className="card-info-icon red">
                                                <span className="material-icons-outlined">notification_important</span>
                                            </div>
                                        }
                                        {(sync.status) ?
                                            <div className="card-info-icon green">
                                                <span className="material-icons-outlined">sync</span>
                                            </div>
                                            :
                                            <div className="card-info-icon red">
                                                <span className="material-icons-outlined">sync_problem</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                        {networkViewerDevices.map( ({id, nickname, sync, hasRecentMissedPill}) => {
                            return(
                                <div className="device-card" key={id} onClick={ () => navigate(`/device/${id}/dashboard`) }>
                                    <h4>{nickname ? nickname : ''}</h4>
                                    {id}
                                    <div className="card-info-icon-container">
                                        {(hasRecentMissedPill) &&
                                            <div className="card-info-icon red">
                                                <span className="material-icons-outlined">notification_important</span>
                                            </div>
                                        }
                                        {(sync.status) ?
                                            <div className="card-info-icon green">
                                                <span className="material-icons-outlined">sync</span>
                                            </div>
                                            :
                                            <div className="card-info-icon red">
                                                <span className="material-icons-outlined">sync_problem</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </>
            }
            {
                invites.length > 0 &&
                <Modal
                    isOpen={showInvitationsModal}
                    onRequestClose={ () => setShowInvitationsModal(false) }
                    overlayClassName="modal-overlay"
                    className="modal-content"
                    closeTimeoutMS={200}
                >
                    <div className="invitations-modal-content">
                        <h4>Invitations</h4>
                        <hr/>
                        <div className="invitations-modal-body">
                            <div className="invitations-index"><h4>{`${inviteModalIndex+1} of ${invites.length}`}</h4></div>
                            <div className="invitations-carousel">
                                <span 
                                    className={`material-icons-outlined ${inviteModalIndex < 1 ? 'disabled' : ''}`}
                                    onClick={ () => {
                                        if(!(inviteModalIndex < 1)){
                                            setInviteModalIndex(inviteModalIndex-1);
                                        }
                                    }}
                                >
                                    chevron_left
                                </span>
                                <div className="invitation-details">
                                    <div className="invited-by">
                                        <Avatar>{invites[inviteModalIndex].invitedBy.displayName}</Avatar>
                                        <div className="invited-by-info">
                                            <h4 className="invited-by-name">{invites[inviteModalIndex].invitedBy.displayName}</h4>
                                            <h5 className="invited-by-email">{invites[inviteModalIndex].invitedBy.email}</h5>
                                        </div>
                                    </div>
                                    <div className="invite-info">
                                        <p><span>Role : </span>{invites[inviteModalIndex].targetRole}</p>
                                        <p><span>Device ID : </span>{invites[inviteModalIndex].deviceId}</p>
                                    </div>
                                    {/* <FormInput type="text" handleChange={ e => setInvitedDeviceNickname(e.target.value) } value={invitedDeviceNickname} label="Device Nickname" required/> */}
                                    <input type="text" onChange={ e => setInvitedDeviceNickname(e.target.value) } value={invitedDeviceNickname} placeholder="Enter Device Nickname" />
                                </div>
                                <span 
                                    className={`material-icons-outlined ${inviteModalIndex >= invites.length-1 ? 'disabled' : ''}`}
                                    onClick={ () => {
                                        if(!(inviteModalIndex >= invites.length-1)){
                                            setInviteModalIndex(inviteModalIndex+1);
                                        }
                                    }}
                                >
                                    chevron_right
                                </span>
                            </div>
                        </div>
                        <hr/>
                        <div className="invitations-modal-footer">
                            <button className="button-reject" onClick={handleRejectInvite}><h4>Reject</h4></button>
                            <div>
                                <button className="button-cancel" onClick={() => setShowInvitationsModal(false)}><h4>Cancel</h4></button>
                                <button className="button-accept" onClick={handleAcceptInvite}><h4>Accept</h4></button>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
            <Modal
                isOpen={showAddNewDeviceModal}
                onRequestClose={ () => setShowAddNewDeviceModal(false) }
                overlayClassName="modal-overlay"
                className="modal-content"
                closeTimeoutMS={200}
            >
                <div className="add-new-device-modal-content">
                    <h4>Add New Device</h4>
                    <hr/>
                    <div className="add-new-device-modal-body">
                        <div className="device-id-form">
                            <FormInput type="text" handleChange={ e => setNewDeviceID(e.target.value) } value={newDeviceID} label="Device ID"/>
                            <div className="vertical-seperator"></div>
                            <div><span className="material-icons-outlined" onClick={() => toast.info('QR scan comming soon!')}>qr_code_scanner</span></div>
                        </div>
                        <FormInput type="text" handleChange={ e => setNewDeviceNickname(e.target.value) } value={newDeviceNickname} label="Device Nickname"/>
                    </div>
                    <hr/>
                    <div className="add-new-device-modal-footer">
                        <button className="button-cancel" onClick={() => setShowAddNewDeviceModal(false)}><h4>Cancel</h4></button>
                        <button className="button-add" onClick={handleAddNewDevice}><h4>Add</h4></button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Devices;