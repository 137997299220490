import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from 'react-modal';

import Avatar from '../../components/avatar/avatar.component';
import { rolePriority } from '../../utilities/constants';

import './network.styles.scss';

const Network = (props) => {

    // state
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [currentUserRole, setCurrentUserRole] = useState('');
    const [networkUsers, setnetworkUsers] = useState([]);
    const [displayedUsers, setDisplayedUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
    const [inviteUserDetails, setInviteUserDetails] = useState(null);
    const [isRevokeInviteModalOpen, setIsRevokeInviteModalOpen] = useState(false);
    const [isRemoveUserModalOpen, setIsRemoveUserModalOpen] = useState(false);
    const [isChangeRoleModalOpen, setIsChangeRoleModalOpen] = useState(false);
    const [changeRoleTargetRole, setChangeRoleTargetRole] = useState(null);

    // refs
    const inviteEmailRef = useRef(null);
    const memberTargetRoleRef = useRef(null);
    const viewerTargetRoleRef = useRef(null);

    // router
    const { deviceId } = useParams();

    useEffect( () => {
        getNetworkUsers();
    }, [])

    useEffect( () => {
        if(searchQuery)
            setDisplayedUsers( networkUsers.filter(user => user.displayName.toLowerCase().includes(searchQuery.toLowerCase())) )
        else
            setDisplayedUsers( networkUsers )
    }, [searchQuery, networkUsers]);

    useEffect( () => {
        setSelectedUsers([]);
    }, [displayedUsers])

    const getNetworkUsers = async() => {
        setIsLoading(true);
        try {
            const {data} = await axios.get(`/app-api/device/${deviceId}/network`);
            if(data){                
                setnetworkUsers(data.networkUsers);
                setCurrentUserRole(data.role)
                setIsLoading(false);
            }
        } catch (err) {
            console.log(err.name);
            setError(err.response.data);
            setIsLoading(false);
        }
    }

    const toggleSelectedUser = (user, checked) => {
        if(checked){
            const newStelectedUsers = [...selectedUsers, {...user}];
            setSelectedUsers( newStelectedUsers );
        }else{
            const newStelectedUsers = selectedUsers.filter( selecteduser => selecteduser.id!=user.id )
            setSelectedUsers( newStelectedUsers );
        }
    }
    const toggleSelectAllUsers = (event) => {
        if(event.target.checked){
            setSelectedUsers( displayedUsers );
        }else{
            setSelectedUsers([]);
        }
    }

    const confirmChangeUserRole = (targetRole = null, targetUsers = selectedUsers, blurElement = null) => {
        if(blurElement)
            blurElement.blur();
        setSelectedUsers(targetUsers);
        setChangeRoleTargetRole(targetRole);
        setIsChangeRoleModalOpen(true);
    }
    const handleChangeUserRole = async() => {
        try {
            const targetUsers = selectedUsers.map( user => user.id );
            const {data} = await axios.post(`/app-api/device/${deviceId}/network/modify`, {
                targetUsers,
                targetRole: changeRoleTargetRole
            });
            getNetworkUsers();
            setIsChangeRoleModalOpen(false);
            toast.success('User Role Changed');
        } catch (err) {
            console.log(err);
            toast.error(err.response.data);
        }
    }

    const confirmRemoveUser = (targetUsers = selectedUsers, blurElement = null) => {
        if(blurElement)
            blurElement.blur();
        setSelectedUsers(targetUsers);
        setIsRemoveUserModalOpen(true);
    }
    const handleRemoveUser = async() => {
        try {
            const targetUsers = selectedUsers.map( user => user.id );
            const {data} = await axios.post(`/app-api/device/${deviceId}/network/remove`, {
                targetUsers
            });
            getNetworkUsers();
            setIsRemoveUserModalOpen(false);
            toast.success('User Removed');
        } catch (err) {
            console.log(err);
            toast.error(err.response.data);
        }
    }

    const confirmRevokeInvitation = (targetUsers = selectedUsers, blurElement = null) => {
        if(blurElement)
            blurElement.blur();
        setSelectedUsers(targetUsers);
        setIsRevokeInviteModalOpen(true);
    }
    const handleRevokeInvitation = async() => {
        try {
            const targetUsers = selectedUsers.map( user => user.id );
            const {data} = await axios.post(`/app-api/device/${deviceId}/network/revoke-invite`, {
                targetUsers
            });
            getNetworkUsers();
            setIsRevokeInviteModalOpen(false);
            toast.success('Invitation revoked');
        } catch (err) {
            console.log(err);
            toast.error(err.response.data);
        }
    }

    const getInvitedUserDetails = async() => {
        try {
            const targetUserEmail = inviteEmailRef.current.value;
            if (!targetUserEmail) return toast.error('Enter an Email');
            const {data} = await axios.post(`/app-api/device/${deviceId}/network/check-invite`, {
                targetUserEmail
            });
            setInviteUserDetails(data);
        } catch (err) {
            console.log(err);
            toast.error(err.response.data);
        }
    }
    const inviteUser = async() => {
        try {
            let targetRole = ''; 
            if (memberTargetRoleRef.current.checked)
                targetRole = "Member";
            if (viewerTargetRoleRef.current.checked)
                targetRole = "Viewer";
            if (!targetRole) return toast.error('Select a permission role');
            const {data} = await axios.post(`/app-api/device/${deviceId}/network/invite`, {
                targetRole,
                targetUserId: inviteUserDetails.id
            });
            getNetworkUsers();
            setInviteUserDetails(null);
            setIsInviteModalOpen(false);
            toast.success('User invited!');
        } catch (err) {
            console.log(err);
            toast.error(err.response.data);
        }
    }

    return(
        <div className="network">
            <h2>This device is shared with</h2>
            <div className="network-header-menu">
                <div className="network-header-menu-search">
                    <span className="material-icons-outlined">search</span>
                    <input type="text" placeholder="Search users" onChange={ e => setSearchQuery(e.target.value)} />
                </div>
                {
                    (currentUserRole >= rolePriority.Owner) &&
                    <div className="network-header-menu-buttons">
                        {
                            (selectedUsers.length>0) &&
                            ( selectedUsers.every( selectedUser => selectedUser.role == "Member" || selectedUser.role == "Viewer" )) &&
                            <>
                                <button className="network-button" onClick={() => confirmChangeUserRole()}>
                                <span className="material-icons-outlined">security</span>
                                    Change Role
                                </button>
                                <button className="network-button" onClick={() => confirmRemoveUser()}>
                                <span className="material-icons-outlined">person_remove</span>
                                    Remove
                                </button>
                            </>
                        }
                        {
                            (selectedUsers.length>0) &&
                            ( selectedUsers.every( selectedUser => selectedUser.role == "Invited" )) &&
                            <>
                                <button className="network-button" onClick={() => confirmRevokeInvitation()}>
                                <span className="material-icons-outlined">block</span>
                                    Revoke Invite
                                </button>
                            </>
                        }
                        <button className="network-button" onClick={() => setIsInviteModalOpen(true)}>
                            <span className="material-icons-outlined">add</span>
                            Invite
                        </button>
                    </div>
                }
            </div>
            <hr/>
            <div className="network-table-header">
                <table cellPadding="0" cellSpacing="0" border="0">
                <thead>
                    <tr>
                        <th>
                            <input
                                type="checkbox"
                                onChange={toggleSelectAllUsers}
                                checked={ selectedUsers.length && selectedUsers.length==displayedUsers.length}
                            />
                        </th>
                        <th>Name</th>
                        <th>Role</th>
                        <th></th>
                    </tr>
                </thead>
                </table>
            </div>
            <hr />
            <div className="network-table-content">
                {
                    isLoading ?
                        <div className="spinner"></div>
                    :
                    <table cellPadding="0" cellSpacing="0" border="0">
                    <tbody>
                        {
                            displayedUsers.map( user => 
                                <tr key={user.id}>
                                    <td>
                                        <input 
                                            type="checkbox"
                                            onChange={(e) => toggleSelectedUser(user, e.target.checked)}
                                            checked={ selectedUsers.some( selecteduser => selecteduser.id==user.id )}
                                        />
                                    </td>
                                    <td>
                                        <Avatar src={user.photoURL}>{user.displayName}</Avatar>
                                        <div>
                                            <div>{user.displayName}</div>
                                            <div>{user.email}</div>
                                        </div>
                                    </td>
                                    <td>{user.role}</td>
                                    <td>
                                    {
                                        (user.role!="Owner" && currentUserRole >= rolePriority.Owner) &&
                                        <div className="dropdown-container" tabIndex="-1">
                                            <div className="three-dots"></div>
                                            <div className="dropdown">
                                                {
                                                    user.role=="Invited" ?
                                                        <div className="dropdown-menuitem" onClick={ (e) => confirmRevokeInvitation([user], e.currentTarget.parentNode.parentNode)}>Revoke</div>
                                                    :
                                                    <>
                                                        {
                                                            <div className="dropdown-menuitem" onClick={(e) => confirmChangeUserRole('Owner', [user], e.currentTarget.parentNode.parentNode)}>
                                                                Owner
                                                            </div>
                                                        }
                                                        {
                                                            user.role=="Member" ?
                                                                <div className="dropdown-menuitem active">
                                                                    <span className="material-icons-outlined">check</span>
                                                                    Member
                                                                </div>
                                                            :
                                                                <div className="dropdown-menuitem" onClick={(e) => confirmChangeUserRole('Member', [user], e.currentTarget.parentNode.parentNode)}>
                                                                    Member
                                                                </div>
                                                        }
                                                        {
                                                            user.role=="Viewer" ?
                                                                <div className="dropdown-menuitem active">
                                                                    <span className="material-icons-outlined">check</span>
                                                                    Viewer
                                                                </div>
                                                            :
                                                                <div className="dropdown-menuitem" onClick={(e) => confirmChangeUserRole('Viewer', [user], e.currentTarget.parentNode.parentNode)}>
                                                                    Viewer
                                                                </div>
                                                        }
                                                        <hr />
                                                        <div className="dropdown-menuitem" onClick={ (e) => confirmRemoveUser([user], e.currentTarget.parentNode.parentNode)}>Remove</div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                    </table>
                }
            </div>
            <Modal
                isOpen={isInviteModalOpen}
                onRequestClose={ () => setIsInviteModalOpen(false) }
                overlayClassName="modal-overlay"
                className="modal-content"
                closeTimeoutMS={200}
            >
                <div className="network-modal-content">
                    <h4>Invite</h4>
                    <hr/>
                    <div className="invite-modal-body">
                        {
                            !inviteUserDetails ?
                                <>
                                    <span className="material-icons-outlined">person_add_alt</span>
                                    <h3>Add an user to this Device</h3>
                                    <input type="email" ref={inviteEmailRef} placeholder="Enter email" />
                                </>
                            :
                                <>
                                    <span className="material-icons-outlined">key</span>
                                    <h3>Select Permission</h3>
                                    <div className="filter-item">
                                        <input type="radio" ref={memberTargetRoleRef} name="permission" id="member" />
                                        <label htmlFor="member">Member</label>
                                    </div>
                                    <div className="filter-item">
                                        <input type="radio" ref={viewerTargetRoleRef} name="permission" id="viewer" />
                                        <label htmlFor="viewer">Viewer</label>
                                    </div>
                                </>
                        }
                    </div>
                    <hr/>
                    <div className="invite-modal-footer">
                        <button
                            className="button-grey"
                            onClick={() => {
                                setInviteUserDetails(null);
                                setIsInviteModalOpen(false)
                            }}
                        >
                            <h4>Cancel</h4>
                        </button>
                        {
                            !inviteUserDetails ?
                                <button className="button-blue" onClick={getInvitedUserDetails} ><h4>Proceed</h4></button>
                            :
                                <div>
                                    <button className="button-grey" onClick={() => setInviteUserDetails(null)} ><h4>Back</h4></button>
                                    <button className="button-blue" onClick={inviteUser} ><h4>Send Invite</h4></button>
                                </div>
                        }
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={isChangeRoleModalOpen}
                onRequestClose={() => {
                    setChangeRoleTargetRole(null);
                    setIsChangeRoleModalOpen(false);
                }}
                overlayClassName="modal-overlay"
                className="modal-content"
                closeTimeoutMS={200}
            >
                <div className="network-modal-content">
                    <div className="invite-modal-body">
                        {
                            !changeRoleTargetRole ?
                                <>
                                    <span className="material-icons-outlined">key</span>
                                    <h3>Select Permission</h3>
                                    <div onChange={ (e) => setChangeRoleTargetRole(e.target.value)}>
                                        <div className="filter-item">
                                            <input type="radio" name="target_role" id="target_role_member" value="Member" />
                                            <label htmlFor="target_role_member">Member</label>
                                        </div>
                                        <div className="filter-item">
                                            <input type="radio" name="target_role" id="target_role_viewer" value="Viewer" />
                                            <label htmlFor="target_role_viewer">Viewer</label>
                                        </div>
                                    </div>
                                </>
                            :
                                <>
                                    <h4>{`Are you sure you want to change permissions for the following users to ${changeRoleTargetRole}?`}</h4>
                                    {selectedUsers.map( selectedUser => <p>{selectedUser.displayName}</p>)}
                                </>
                        }
                    </div>
                    <hr/>
                    <div className="invite-modal-footer">
                        <button 
                            className="button-grey" 
                            onClick={() => {
                                setChangeRoleTargetRole(null);
                                setIsChangeRoleModalOpen(false);
                            }}
                        >
                            <h4>Cancel</h4>
                        </button>
                        {
                            changeRoleTargetRole &&
                            <button className="button-red" onClick={handleChangeUserRole} >
                                <h4>{ changeRoleTargetRole == "Owner" ? 'Transfer Ownership' : 'Change Permission' }</h4>
                            </button>
                        }
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={isRevokeInviteModalOpen}
                onRequestClose={ () => setIsRevokeInviteModalOpen(false) }
                overlayClassName="modal-overlay"
                className="modal-content"
                closeTimeoutMS={200}
            >
                <div className="network-modal-content">
                    <h4>Are you sure you want to revoke invitation for the following users?</h4>
                    <div className="invite-modal-body">
                        {
                            selectedUsers.map( selectedUser => <p>{selectedUser.displayName}</p>)
                        }
                    </div>
                    <hr/>
                    <div className="invite-modal-footer">
                        <button className="button-grey" onClick={() => setIsRevokeInviteModalOpen(false)}><h4>Cancel</h4></button>
                        <button className="button-red" onClick={handleRevokeInvitation} ><h4>Revoke Invite</h4></button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={isRemoveUserModalOpen}
                onRequestClose={ () => setIsRemoveUserModalOpen(false) }
                overlayClassName="modal-overlay"
                className="modal-content"
                closeTimeoutMS={200}
            >
                <div className="network-modal-content">
                    <h4>Are you sure you want to remove the following users from your Device Network?</h4>
                    <div className="invite-modal-body">
                        {
                            selectedUsers.map( selectedUser => <p>{selectedUser.displayName}</p>)
                        }
                    </div>
                    <hr/>
                    <div className="invite-modal-footer">
                        <button className="button-grey" onClick={() => setIsRemoveUserModalOpen(false)}><h4>Cancel</h4></button>
                        <button className="button-red" onClick={handleRemoveUser} ><h4>Remove</h4></button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Network;