import { useEffect, useContext } from "react";
import Modal from "react-modal";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import axios from "axios";

import { Context } from "./context/context";

import SignUp from "./pages/sign-up/sign-up";
import SignIn from "./pages/sign-in/sign-in";
import SignOut from "./pages/sign-out/sign-out";
import ForgotPassword from "./pages/forgot-password/forgot-password.component";
import PageLayout from "./components/layout/page-layout.component";
import Devices from "./pages/devices/devices";
import Dashboard from "./pages/dashboard/dashboard.component";
import Schedule from "./pages/schedule/schedule.component";
import Notifications from "./pages/notifications/notifications.component";
import Network from "./pages/network/network.component";
import DeviceSettings from "./pages/device-settings/device-settings.component";
// import Store from "./pages/store/store";
import Profile from "./pages/profile/profile.component.";

import { messaging } from "./firebase/firebase.utils";
import { getToken } from "firebase/messaging";

import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-input-2/lib/style.css";

function App() {
  // state
  const { state } = useContext(Context);
  const { user } = state;

  useEffect(() => {
    Modal.setAppElement("#root");
  }, []);

  useEffect(() => {
    if (user) {
      setUpNotifications();
    }
    // eslint-disable-next-line
  }, [user?.email]);

  const setUpNotifications = async () => {
    // Request user for notification permission.
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      try {
        // Generate token
        const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY });
        // Register token
        await axios.post("/app-api/messaging/subscribe", { token });
      } catch (err) {
        console.error("Error subscribing for notifications");
      }
    }
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signout" element={<SignOut />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route element={<PageLayout />}>
          <Route path="/" element={<Devices />} />
          <Route path="/device/:deviceId/dashboard" element={<Dashboard />} />
          <Route path="/device/:deviceId/schedule" element={<Schedule />} />
          <Route path="/device/:deviceId/notifications" element={<Notifications />} />
          <Route path="/device/:deviceId/network" element={<Network />} />
          {/* <Route path="/device/:deviceId/store" element={<Store/>} /> */}
          <Route path="/device/:deviceId/settings" element={<DeviceSettings />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
      </Routes>
      <ToastContainer position="bottom-center" theme="dark" style={{ fontSize: "0.85em" }} />
    </div>
  );
}

export default App;
