import axios from "axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { signInWithEmailAndPassword, getIdToken, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../../firebase/firebase.utils";
import { Context } from "../../context/context";

import FormInput from "../../components/form-input/form-input.component";
import CustomButton from "../../components/custom-button/custom-button.component";

import GoogleLogo from '../../assets/google.png'
import './sign-in.styles.scss';

const SignIn = (props) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    //state
    const { state, dispatch } = useContext(Context);

    // router
    const navigate = useNavigate();

    const handleEmailSignIn = async(event) => {
        event.preventDefault();
        const toastId = toast.loading("Authenticating...");
        try {
            setIsLoading(true);
            const {user} = await signInWithEmailAndPassword(auth, email, password);
            const idToken = await getIdToken(user);
            const {data} = await axios.post('/app-api/user/login', {idToken});
            await auth.signOut();
            dispatch({
                type: "LOGIN",
                payload: data
            });
            window.sessionStorage.setItem('user', JSON.stringify(data));
            toast.update(toastId, { render: `Authenticated as ${data.email}`, type: "success", isLoading: false, autoClose: 2000, delay: 100 });
            setIsLoading(false);
            // redirect
            navigate('/');
        } catch (err) {
            console.log(err);
            switch (err.code){
                case 'auth/invalid-email':
                    toast.update(toastId, { render: "The Email ID is invalid.", type: "error", isLoading: false, autoClose: 2000, delay: 100 });
                    break;
                case 'auth/user-disabled':
                    toast.update(toastId, { render: "This user has been diabled.", type: "error", isLoading: false, autoClose: 2000, delay: 100 });
                    break;
                case 'auth/user-not-found':
                    toast.update(toastId, { render: "The MedpeR account does not exist.", type: "error", isLoading: false, autoClose: 2000, delay: 100 });
                    break;
                case 'auth/wrong-password':
                    toast.update(toastId, { render: "Incorrect Password.", type: "error", isLoading: false, autoClose: 2000, delay: 100 });
                    break;
                default:
                    toast.update(toastId, { render: "Something went wrong", type: "error", isLoading: false, autoClose: 2000, delay: 100 });
            }
            setIsLoading(false);
        }
    }
    
    const handleGoogleSignIn = async() => {
        const toastId = toast.loading("Authenticating...");
        try {
            setIsLoading(true);
            // Sign in using a popup.
            const googleProvider = new GoogleAuthProvider();
            const {user} = await signInWithPopup(auth, googleProvider);
            const idToken = await getIdToken(user);
            const {data} = await axios.post('/app-api/user/login', {idToken});
            await auth.signOut();
            dispatch({
                type: "LOGIN",
                payload: data,
            });
            window.sessionStorage.setItem('user', JSON.stringify(data));
            toast.update(toastId, { render: `Authenticated as ${data.email}`, type: "success", isLoading: false, autoClose: 2000, delay: 100 });
            setIsLoading(false);
            // redirect
            navigate('/');
        }catch (err) {
            console.log('err', err);
            switch (err.code){
                case 'auth/popup-blocked':
                    toast.update(toastId, { render: "Popup Blocked. Please enable popup in your browser.", type: "error", isLoading: false, autoClose: 2000, delay: 100 });
                    break;
                case 'auth/popup-closed-by-user':
                    toast.update(toastId, { render: "Popup closed.", type: "error", isLoading: false, autoClose: 2000, delay: 100 });
                    break;
                case 'auth/cancelled-popup-request':
                    toast.update(toastId, { render: "Popup is already open.", type: "error", isLoading: false, autoClose: 2000, delay: 100 });
                    break;
                default:
                    toast.update(toastId, { render: "Something went wrong", type: "error", isLoading: false, autoClose: 2000, delay: 100 });
            }
            setIsLoading(false);
        }
    }

    return(
        <div className="signin-page-background">
            <div className="signin-page-content">
                <h2 className="title">Sign in with Email and Password</h2>
                <form onSubmit={handleEmailSignIn}>
                    <FormInput type="email" handleChange={ e => setEmail(e.target.value.trim()) } value={email} label="Email" required/>
                    <FormInput type="password" handleChange={ e => setPassword(e.target.value) } value={password} label="Password" required/>
                    <CustomButton type="submit" id="sign-in-button">Sign In</CustomButton>
                </form>
                <div className="supporting-links-container">
                    <h5 type="button" onClick={()=> navigate('/forgotpassword')}>Forgot Password ?</h5>
                </div>
                <div id="sign-in-with">
                    <h4><span>or Sign in with</span></h4>
                    <div className="sso-buttons">
                        <button onClick={handleGoogleSignIn}><img src={GoogleLogo} />Sign in with Google</button>
                    </div>
                </div>
                <hr />
                <div id="content-footer">
                    <h2 className="footer-header">No account?</h2>
                    <CustomButton inverted type="button" id="footer-button" onClick={()=> navigate(`/signup`)}>Sign Up</CustomButton>
                </div>
                {isLoading &&
                    <div className="disable-form" onClick={e => e.stopPropagation()}></div>
                }
            </div>
        </div>
    )
}

export default SignIn;