import { useEffect, useState } from "react";
import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";

import { ReactComponent as LogoBig } from "../../assets/MEDPER INFINITY.svg";
import { ReactComponent as LogoSmall } from "../../assets/M.svg";

import "./side-nav.styles.scss";

const SideNav = (props) => {
  // props
  const { isOverlay, closeOverlay } = props;

  // state
  const [isExpanded, setIsExpanded] = useState(window.innerWidth > 992 ? true : false);

  // router
  const resolved = useResolvedPath("/device/:deviceId/:page");
  const match = useMatch({ path: resolved.pathname, end: true });
  const navigate = useNavigate();

  // componentDidMount(){
  //     window.onresize = (event) => {
  //         if(event.target.innerWidth > 992 && this.state.isExpanded===false)
  //             this.setState({ isExpanded: true})
  //         if(event.target.innerWidth <= 992 && this.state.isExpanded===true)
  //             this.setState({ isExpanded: false})
  //     }
  // }

  useEffect(() => {
    if (isOverlay) {
      setIsExpanded(true);
    } else {
      setIsExpanded(window.innerWidth > 992 ? true : false);
    }
  }, [isOverlay]);

  const handleNavigate = (path) => {
    navigate(path);
    closeOverlay();
  };

  return (
    <div className={`blade ${isExpanded ? "expanded" : ""}`}>
      <div className="blade-logo">{isExpanded ? <LogoBig className="logo-big" /> : <LogoSmall className="logo-small" />}</div>
      <div className="blade-top">
        <ul className="blade-menu">
          <li
            className={`${match && match.params.page === "dashboard" ? "active" : ""}`}
            onClick={() => handleNavigate(`/device/${match.params.deviceId}/dashboard`)}
          >
            <div className="icon">
              <svg id="dashboard-icon" enableBackground="new 0 0 511.995 511.995" viewBox="0 0 511.995 511.995" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <path d="m507.308 236.641-198.98-198.99c-28.86-28.85-75.8-28.85-104.66 0l-198.98 198.99c-6.25 6.25-6.25 16.38 0 22.63 6.24 6.25 16.38 6.25 22.62 0l12.69-12.69v203.4c0 25.405 20.595 46 46 46h80c5.523 0 10-4.477 10-10v-148c0-27.614 22.386-50 50-50h60c27.614 0 50 22.386 50 50v148c0 5.523 4.477 10 10 10h80c25.405 0 46-20.595 46-46v-203.4l12.69 12.69c3.12 3.12 7.22 4.69 11.31 4.69s8.19-1.57 11.31-4.69c6.25-6.25 6.25-16.38 0-22.63z" />
                </g>
              </svg>
            </div>
            <div className="label">Dashboard</div>
          </li>
          <li
            className={`${match && match.params.page === "schedule" ? "active" : ""}`}
            onClick={() => handleNavigate(`/device/${match.params.deviceId}/schedule`)}
          >
            <div className="icon">
              <svg id="schedule-icon" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <path d="m512 169c0-33.41 0-56.783 0-59 0-24.813-20.187-45-45-45h-6v55c0 8.284-6.716 15-15 15s-15-6.716-15-15c0-16.839 0-63.232 0-80 0-8.284-6.716-15-15-15s-15 6.716-15 15v25h-100v55c0 8.284-6.716 15-15 15s-15-6.716-15-15c0-16.839 0-63.232 0-80 0-8.284-6.716-15-15-15s-15 6.716-15 15v25h-100v55c0 8.284-6.716 15-15 15s-15-6.716-15-15c0-16.839 0-63.232 0-80 0-8.284-6.716-15-15-15s-15 6.716-15 15v25h-36c-24.813 0-45 20.187-45 45v59z" />
                  <path d="m0 199v243c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 0-6.425 0-146.812 0-243-9.335 0-506.836 0-512 0zm144 208h-32c-8.284 0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-32c-8.284 0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-32c-8.284 0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15 15zm128 128h-32c-8.284 0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-32c-8.284 0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-32c-8.284 0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15 15zm128 128h-32c-8.284 0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-32c-8.284 0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-64h-32c-8.284 0-15-6.716-15-15s6.716-15 15-15h32c8.284 0 15 6.716 15 15s-6.716 15-15 15z" />
                </g>
              </svg>
            </div>
            <div className="label">Schedule</div>
          </li>
          <li
            className={`${match && match.params.page === "notifications" ? "active" : ""}`}
            onClick={() => handleNavigate(`/device/${match.params.deviceId}/notifications`)}
          >
            <div className="icon">
              <svg
                id="notifications-icon"
                enableBackground="new 0 0 511.156 511.156"
                viewBox="0 0 511.156 511.156"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m184.904 465.044c11.999 27.127 39.154 46.112 70.674 46.112s58.674-18.985 70.674-46.112z" />
                <path d="m255.573 48.836c20.8 0 40.772 3.67 59.306 10.389v-2.283c0-31.398-25.544-56.942-56.941-56.942h-4.719c-31.398 0-56.942 25.544-56.942 56.942v2.254c18.524-6.699 38.49-10.36 59.296-10.36z" />
                <path d="m442.747 435.044h-374.338c-7.082 0-13.569-4.776-15.042-11.704-1.458-6.859 1.668-13.629 8.01-16.559 1.505-.976 12.833-8.897 24.174-32.862 20.829-44.01 25.201-106.005 25.201-150.263 0-79.855 64.967-144.82 144.821-144.82 79.665 0 144.512 64.652 144.82 144.245.007.191.011.383.011.575 0 44.258 4.372 106.253 25.201 150.263 11.341 23.965 22.668 31.887 24.174 32.862 6.342 2.93 9.469 9.699 8.01 16.559-1.473 6.927-7.959 11.704-15.042 11.704zm7.2-28.157h.01z" />
              </svg>
            </div>
            <div className="label">Notifications</div>
          </li>
          <li
            className={`${match && match.params.page === "network" ? "active" : ""}`}
            onClick={() => handleNavigate(`/device/${match.params.deviceId}/network`)}
          >
            <div className="icon">
              <svg id="network-icon" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4" cy="10" r="2" />
                <path d="m6.67 13.4c-1.01.76-1.67 1.98-1.67 3.35v.25h-4.25c-.41 0-.75-.34-.75-.75v-.5c0-1.52 1.23-2.75 2.75-2.75h2.5c.52 0 1.01.15 1.42.4z" />
                <circle cx="20" cy="10" r="2" />
                <path d="m24 15.75v.5c0 .41-.34.75-.75.75h-4.25v-.25c0-1.37-.66-2.59-1.67-3.35.41-.25.9-.4 1.42-.4h2.5c1.52 0 2.75 1.23 2.75 2.75z" />
                <circle cx="12" cy="9.5" r="3" />
                <path d="m14.75 14h-5.5c-1.517 0-2.75 1.233-2.75 2.75v1.5c0 .414.336.75.75.75h9.5c.414 0 .75-.336.75-.75v-1.5c0-1.517-1.233-2.75-2.75-2.75z" />
              </svg>
            </div>
            <div className="label">Device Network</div>
          </li>
          <li
            className={`${match && match.params.page === "settings" ? "active" : ""}`}
            onClick={() => handleNavigate(`/device/${match.params.deviceId}/settings`)}
          >
            <div className="icon">
              <svg
                version="1.1"
                id="settings-icon"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512" }}
                xmlSpace="preserve"
              >
                <g>
                  <g>
                    <path d="M256,181c-41.353,0-75,33.647-75,75c0,41.353,33.647,75,75,75c41.353,0,75-33.647,75-75C331,214.647,297.353,181,256,181z" />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M512,298.305v-84.609l-69.408-13.667c-3.794-12.612-8.833-24.771-15.103-36.343l38.73-58.096l-59.81-59.81l-58.096,38.73
                                            c-11.572-6.27-23.73-11.309-36.343-15.103L298.305,0h-84.609l-13.667,69.408c-12.612,3.794-24.771,8.833-36.343,15.103
                                            L105.59,45.78l-59.81,59.81l38.73,58.096c-6.27,11.572-11.309,23.73-15.103,36.343L0,213.695v84.609l69.408,13.667
                                            c3.794,12.612,8.833,24.771,15.103,36.343L45.78,406.41l59.81,59.81l58.096-38.73c11.572,6.27,23.73,11.309,36.343,15.103
                                            L213.695,512h84.609l13.667-69.408c12.612-3.794,24.771-8.833,36.343-15.103l58.096,38.73l59.81-59.81l-38.73-58.096
                                            c6.27-11.572,11.309-23.73,15.103-36.343L512,298.305z M256,361c-57.891,0-105-47.109-105-105s47.109-105,105-105
                                            s105,47.109,105,105S313.891,361,256,361z"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div className="label">Device Settings</div>
          </li>
          {/* <li
            className={`${match && match.params.page === "store" ? "active" : ""}`}
            onClick={() => handleNavigate(`/device/${match.params.deviceId}/store`)}
          >
            <div className="icon">
              <svg id="store-icon" viewBox="0 -31 512.00033 512" xmlns="http://www.w3.org/2000/svg">
                <path d="m166 300.003906h271.003906c6.710938 0 12.597656-4.4375 14.414063-10.882812l60.003906-210.003906c1.289063-4.527344.40625-9.390626-2.433594-13.152344-2.84375-3.75-7.265625-5.964844-11.984375-5.964844h-365.632812l-10.722656-48.25c-1.523438-6.871094-7.617188-11.75-14.648438-11.75h-91c-8.289062 0-15 6.710938-15 15 0 8.292969 6.710938 15 15 15h78.960938l54.167968 243.75c-15.9375 6.929688-27.128906 22.792969-27.128906 41.253906 0 24.8125 20.1875 45 45 45h271.003906c8.292969 0 15-6.707031 15-15 0-8.289062-6.707031-15-15-15h-271.003906c-8.261719 0-15-6.722656-15-15s6.738281-15 15-15zm0 0" />
                <path d="m151 405.003906c0 24.816406 20.1875 45 45.003906 45 24.8125 0 45-20.183594 45-45 0-24.8125-20.1875-45-45-45-24.816406 0-45.003906 20.1875-45.003906 45zm0 0" />
                <path d="m362.003906 405.003906c0 24.816406 20.1875 45 45 45 24.816406 0 45-20.183594 45-45 0-24.8125-20.183594-45-45-45-24.8125 0-45 20.1875-45 45zm0 0" />
              </svg>
            </div>
            <div className="label">Store</div>
          </li>
          <li>User Guides</li>
          <li>FAQs</li> */}
        </ul>
      </div>
      <div className="blade-footer">
        {isOverlay ? (
          <div className="right-arrow" onClick={closeOverlay}>
            <span className="material-icons-outlined" id="close-arrow-icon">
              close
            </span>
          </div>
        ) : isExpanded ? (
          <div className="left-arrow" onClick={() => setIsExpanded(false)}>
            <svg
              version="1.1"
              id="left-arrow-icon"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 199.404 199.404"
              style={{ enableBackground: "new 0 0 199.404 199.404" }}
              xmlSpace="preserve"
            >
              <g>
                <polygon points="135.412,0 35.709,99.702 135.412,199.404 163.695,171.119 92.277,99.702 163.695,28.285 	" />
              </g>
            </svg>
          </div>
        ) : (
          <div className="right-arrow" onClick={() => setIsExpanded(true)}>
            <svg
              version="1.1"
              id="right-arrow-icon"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 306 306"
              style={{ enableBackground: "new 0 0 306 306" }}
              xmlSpace="preserve"
            >
              <g>
                <g id="chevron-right">
                  <polygon points="94.35,0 58.65,35.7 175.95,153 58.65,270.3 94.35,306 247.35,153 		" />
                </g>
              </g>
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideNav;
