import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Modal from "react-modal";
import { DateTime } from "luxon";
import { Context } from "../../context/context";

import CustomButton from "../custom-button/custom-button.component";

import "./profile-close-account.styles.scss";

const ProfileCloseAccount = ( props ) => {
    // state
    const [isLoading, setIsLoading] = useState(false);
    const [accountCheckStatus, setAccountCheckStatus] = useState(null);
    const [showActiveDevicesModal, setShowActiveDevicesModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    // state
    const { state, dispatch } = useContext(Context);
    const { user } = state;

    // router
    const navigate = useNavigate();

    const handleLoadCheckStatus = async() => {
        setIsLoading(true);
        try {
            const {data} = await axios.get(`/app-api/user/check-close`);
            if(data.canClose){
                setShowConfirmationModal(true);
            }else{
                setAccountCheckStatus(data);
                setShowActiveDevicesModal(true);
            }
        } catch (err) {
            console.log(err);
            toast.error('Failed to load account status.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeviceScheduleReset = async(deviceID) => {
        setIsLoading(true);
        try {
            const {data} = await axios.post(`/app-api/device/${deviceID}/reset-schedule`);
            toast.success('Device reset successful.');
            const updatedDevicesToCheck = [];
            accountCheckStatus.devicesToCheck.forEach( device => {
                if(device.id !== deviceID){
                    updatedDevicesToCheck.push({...device});
                }
            })
            if(updatedDevicesToCheck.length === 0){             // no more devices that require attention
                setShowActiveDevicesModal(false);
                setAccountCheckStatus(null);
                setShowConfirmationModal(true);
            }else{                                              // handle remaining devices that require attention
                setAccountCheckStatus({
                    ...accountCheckStatus,
                    devicesToCheck: updatedDevicesToCheck,
                });
            }
        } catch (err) {
            console.log(err);
            toast.error('Failed to reset device.');
        } finally {
            setIsLoading(false);
        }
    };
    
    const handleNavigateToDeviceNetwork = (deviceID) => { navigate(`/device/${deviceID}/network`) };
    
    const handleCloseAccount = async() => {
        if(!isLoading){
            setIsLoading(true);
            try {
                const {data} = await axios.delete(`/app-api/user/close-account`);
                toast.success("Account Deleted Successfully.");
                window.sessionStorage.removeItem('user');
                dispatch({type: 'LOGOUT'});
                navigate('/signin');
            } catch (err) {
                console.log(err);
                toast.error(err.validationError ? err.validationError : "Something went wrong");
            } finally {
                setIsLoading(false);
            }
        }
    }
    
    return(
        <div className="profile-close-account">
            <div className="form-group">
                <div className="label">
                    <div className="text">Close Account Permanently</div>
                    <div className="icon"><span className="material-icons-outlined">priority_high</span></div>
                </div>
                <div className="warning-text">
                    <span className="highlight">Warning:</span>If you close your account, your data will be removed permanently. Deleting your account cannot be undone. You will be able to create a new account with your email address.
                    <br/>
                    <div className="check-to-continue">Are you sure to continue?</div>
                </div>
            </div>
            <div className="button-row">
                <CustomButton onClick={handleLoadCheckStatus}>{isLoading ? <div className="spinner"></div> : 'Close Account'}</CustomButton>
            </div>
            <Modal
                isOpen={showActiveDevicesModal}
                onRequestClose={ () => {
                    setAccountCheckStatus(null);
                    setShowActiveDevicesModal(false);
                }}
                overlayClassName="modal-overlay"
                className="modal-content"
                closeTimeoutMS={200}
            >
                <div className="active-device-modal-content">
                    <h4>Following Devices need your attention</h4>
                    <hr/>
                    <div className="instructions">
                        You are the sole owner of the following active device(s), associated with your account. Before deleting your account, you can either add another user to the device to ensure continued usage, or reset the device to remove any active alarms and pill information.
                    </div>
                    <div className="device-list">
                        {
                            accountCheckStatus && accountCheckStatus.devicesToCheck.map( device => (
                                <div className="device-item" key={device.id}>
                                    <div className="item-card">
                                        <div className="device-name">
                                            <div className="nickname">{device.nickname}</div>
                                            <div className="id">Device ID : {device.id}</div>
                                        </div>
                                        <div className="activated-time">
                                            <span className="label">Activated at</span>
                                            <br/>
                                            {DateTime.fromISO(device.activated_at).toFormat('dd-MMM-yyyy')}
                                        </div>
                                    </div>
                                    <div className="actions-dropdown">
                                        <button onClick={() => handleNavigateToDeviceNetwork(device.id)}>Add an user</button>
                                        <button onClick={() => handleDeviceScheduleReset(device.id)}>Reset Device</button>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <hr/>
                    <div className="active-device-modal-content-footer">
                        <button
                            className="cancel"
                            onClick={() => {
                                setAccountCheckStatus(null);
                                setShowActiveDevicesModal(false);
                            }}
                        >
                            <h4>Cancel</h4>
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={showConfirmationModal}
                onRequestClose={ () => {
                    setShowConfirmationModal(false);
                }}
                overlayClassName="modal-overlay"
                className="modal-content"
                closeTimeoutMS={200}
            >
                <div className="confirm-close-account-modal-content">
                    <h4>Are you sure to continue?</h4>
                    <hr/>
                    <div className="confirm-close-account-modal-footer">
                        <button className="cancel" onClick={() => setShowConfirmationModal(false)}><h4>Cancel</h4></button>
                        <button className="confirm" onClick={handleCloseAccount}><h4>Confirm</h4></button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ProfileCloseAccount;