import { useState, useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Context } from "../../context/context";
import useOnClickOutside from "../../hooks/useOnClickOutside";

import Avatar from "../avatar/avatar.component";

import { ReactComponent as LogoBig } from "../../assets/MEDPER INFINITY.svg";

import "./header.styles.scss";

const Header = (props) => {
  // state
  const [isUserMenuCollapsed, setIsUserMenuCollapsed] = useState(true);
  const [isDevicesMenuCollapsed, setIsDevicesMenuCollapsed] = useState(true);

  // state
  const {
    state,
    // dispatch,
  } = useContext(Context);
  const { user, scrollPosition } = state;

  // router
  const navigate = useNavigate();
  const { deviceId } = useParams();

  // refs
  const userMenuDropdownRef = useRef(null);
  const devicesMenuDropdownRef = useRef(null);

  // hooks
  useOnClickOutside(userMenuDropdownRef, () => setIsUserMenuCollapsed(true));
  useOnClickOutside(devicesMenuDropdownRef, () => setIsDevicesMenuCollapsed(true));

  return (
    <div className={`header ${scrollPosition > 10 ? "show-border" : ""}`}>
      {props.showMenuButton && (
        <button className="menu-icon" onClick={() => props.setShowSideNav(true)}>
          <span className="material-icons-outlined">menu</span>
        </button>
      )}
      {!deviceId && (
        <div className="header-logo">
          <LogoBig className="medper-logo" />
        </div>
      )}
      {deviceId && (
        <button className="device-selector" onClick={() => setIsDevicesMenuCollapsed(!isDevicesMenuCollapsed)} ref={devicesMenuDropdownRef}>
          <h5 className="device-nickname">{user.nicknames[deviceId]}</h5>
          {isDevicesMenuCollapsed ? (
            <span className="material-icons-outlined">expand_more</span>
          ) : (
            <span className="material-icons-outlined">expand_less</span>
          )}
          {!isDevicesMenuCollapsed && (
            <div className="dropdown">
              <div className="dropdown-menuitem" onClick={() => navigate("/")}>
                See All Devices
              </div>
            </div>
          )}
        </button>
      )}
      <div className="spacer"></div>
      <div className="header-user" onClick={() => setIsUserMenuCollapsed(!isUserMenuCollapsed)} tabIndex="0" ref={userMenuDropdownRef}>
        <Avatar src={user.photoURL}>{user.displayName}</Avatar>
        {!isUserMenuCollapsed && (
          <div className="dropdown">
            <div className="dropdown-menuitem" onClick={() => navigate("/profile")}>
              <span className="material-icons-outlined">person_outline</span>
              Profile
            </div>
            <div className="dropdown-menuitem" onClick={() => navigate("/signout")}>
              <span className="material-icons-outlined">logout</span>
              Sign Out
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
