import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { Context } from "../../context/context";
import CustomButton from "../../components/custom-button/custom-button.component";

import "./device-settings.styles.scss";

const DeviceSettings = () => {
  // state
  const [isLoading, setIsLoading] = useState(false);
  // const [userRole, setUserRole] = useState(null);
  const [nickname, setNickname] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState({});

  // context
  const {
    // state,
    dispatch,
  } = useContext(Context);

  // router
  const { deviceId } = useParams();

  useEffect(() => {
    getSettingsData();
    // eslint-disable-next-line
  }, []);

  const getSettingsData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`/app-api/device/${deviceId}/settings`);
      const {
        // role,
        nickname,
        // timezoneOffset,
        timezoneValue,
      } = data;
      // setUserRole(role);
      setNickname(nickname);
      setSelectedTimezone(timezoneValue);
    } catch (err) {
      // toast.error
      console.error(err);
      toast.error("Failed to load data");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    if (!isLoading) {
      try {
        setIsLoading(true);
        await axios.post(`/app-api/device/${deviceId}/nickname`, {
          nickname,
        });
        dispatch({ type: "CHANGE_NICKNAME", payload: { deviceId, nickname } });
        toast.success("Settings updated!");
      } catch (err) {
        console.error(err);
        toast.error("Something went wrong");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="settings-page">
      <h2>Device Settings</h2>
      <div className="form-group">
        <div className="label">
          <div className="text">Nick Name</div>
        </div>
        <input
          className={`input-field input-name ${false ? "empty" : ""}`}
          type="text"
          placeholder="Enter Nick Name"
          value={nickname}
          onChange={(e) => {
            setNickname(e.target.value);
          }}
        />
      </div>
      <div className="form-group">
        <div className="label">
          <div className="text">Device Timezone</div>
        </div>
        <input className={`input-field`} type="text" placeholder="Select Timezone" value={selectedTimezone} disabled />
      </div>
      <div className="button-row">
        <CustomButton onClick={handleSave}>{isLoading ? <div className="spinner"></div> : "SAVE"}</CustomButton>
      </div>
    </div>
  );
};

export default DeviceSettings;
