export const rolePriority = Object.freeze({
  Admin: 50,
  Owner: 40,
  Member: 30,
  Viewer: 10,
});

export const Genders = ["Male", "Female", "Others"];

export const INITIALIZE_ORDER_URL = `/app-api/initializeOrder/`;
export const VERIFY_PAYMENT_URL = "/app-api/verifyPayment";
export const FETCH_PLANS = "/app-api/plans";
