import { createContext, useReducer } from "react";

// initial state
const initialState = {
  user: JSON.parse(window.sessionStorage.getItem("user")),
  scrollPosition: 0,
};

// create context
const Context = createContext();

// root reducer
const rootReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, user: action.payload };
    case "LOGOUT":
      return { ...state, user: null };
    case "UPDATE_PROFILE": {
      const updatedUser = { ...state.user };
      const { payload } = action;
      updatedUser.displayName = payload.displayName;
      updatedUser.gender = payload.gender;
      updatedUser.dob = payload.dob;
      updatedUser.phoneNumber = payload.phoneNumber;
      updatedUser.ABHANumber = payload.ABHANumber;
      return { ...state, user: { ...updatedUser } };
    }
    case "UPDATE_PHOTO": {
      const updatedUser = { ...state.user };
      updatedUser.photoURL = action.payload;
      return { ...state, user: { ...updatedUser } };
    }
    case "CHANGE_NICKNAME": {
      const updatedUser = { ...state.user };
      const { deviceId, nickname } = action.payload;
      updatedUser.nicknames[deviceId] = nickname;
      return { ...state, user: { ...updatedUser } };
    }
    case "SET_SCROLL_POSITION":
      return { ...state, scrollPosition: action.payload };
    default:
      return state;
  }
};

// create provider
const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);

  // useEffect(() => {
  //     dispatch({
  //         type: "LOGIN",
  //         payload: JSON.parse(window.sessionStorage.getItem('user')),
  //     })
  // }, []);

  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

export { Context, Provider };
